form {
  background: linear-gradient(
    0deg,
    var(--color-primary) 0%,
    var(--color-primary-light) 100%
  );
  padding: 3.5rem 2rem;
  margin-bottom: 3rem;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  box-shadow: 0 0.8rem 1rem rgba(0, 0, 0, 0.3) !important;
}

form p {
  color: white;
  width: 50%;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
}

form p span {
  font-size: 1.3rem;
  font-weight: 700;
}

#Contact h1 {
  margin: 2.5rem auto !important;
}

input.form-control,
textarea.form-control {
  width: 25rem;
  margin: 0 0.5rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.4);
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  border-radius: 16px;
  border: none;
  box-shadow: rgb(38, 57, 77) 0px 14px 12px -10px;
  transition: all 0.2s ease-in-out;
  resize: none;
}

input:hover,
textarea:hover,
input:focus,
textarea:focus {
  transform: scale(0.98);
  box-shadow: 0 0 0 0;
  background-color: rgba(100, 100, 100, 0.4) !important;
}

input:focus,
textarea:focus {
  color: white !important;
}

input.form-control::placeholder,
textarea.form-control::placeholder {
  color: white;
  font-weight: 500;
}

.invalid-feedback {
  position: absolute;
  bottom: -22px;
  font-size: 12px;
  margin: 0.5rem 0 0 1rem;
  font-weight: 600;
}

.message {
  resize: none;
}

#Contact button {
  padding: 0.7rem 3.5rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 15px;
  border: 2px solid white;
  color: white;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  box-shadow: rgb(38, 57, 77) 0px 18px 12px -10px;
}

#Contact button:hover,
#Contact button:active {
  transform: scale(0.95);
  background: linear-gradient(
    180deg,
    var(--color-primary) 0%,
    var(--color-primary-light) 100%
  );
  color: white;
  border: 2px solid white;
  box-shadow: 0 0 0 0;
}

#Contact button:focus {
  outline: none;
}

#Contact button:active {
  transform: scale(1);
}

#Contact button i {
  margin-left: 0.2rem;
}

#Contact button.reset {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  padding: 0.5rem 2.5rem;
}

.bottomContainer {
  position: relative;
  width: max-content;
  display: flex;
  justify-content: center;
}

.bottomMessage {
  position: absolute;
  width: max-content !important;
  display: inline;
  bottom: -3.5rem;
  text-transform: capitalize;
  background-color: rgba(230, 230, 230, 0.8);
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: unset;
  padding: 10px;
  border-radius: 10px;
}

@media screen and (max-width: 767px) {
  form {
    gap: 1rem;
  }

  form p {
    width: 100%;
    font-size: 1rem;
  }

  form p span {
    font-size: 1.2rem;
    font-weight: 700;
  }

  #Contact h1 {
    width: 80%;
    font-size: 1.8rem;
    text-align: center;
  }

  input.form-control,
  textarea.form-control {
    width: 17rem;
    font-size: 12px;
  }

  #Contact button {
    padding: 0.7rem 3rem;
    font-size: 0.9rem;
  }

  #Contact button.reset {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    padding: 0.5rem 2rem;
  }

  .bottomMessage {
    font-size: 12px;
    bottom: -3.3rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 940px) {
  #Contact h1 {
    width: 40%;
  }
}

@media screen and (min-width: 941px) and (max-width: 1200px) {
  .formContainer {
    max-width: 90%;
  }
}
